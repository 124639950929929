.coin-list {
  padding-bottom: 3.95833vw;
  @include mq(1920) {
    padding: 0 10px 76px;
  }
}

.coin-list__popular {
  display: flex;
  justify-content: center;
  margin: 2.812vw 0 7.4479vw;

  @include mq(1920) {
    margin: 54px 0 143px;
  }
  @include mq(1250) {
    margin: 54px 0 103px;
  }
  @include mq(767) {
    margin: 54px 0 73px;
  }
  @include mq(600) {
    flex-direction: column;
    align-items: center;
  }
}

.coin-list__popular-item {
  margin: 0 2.2395vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq(1920) {
    margin: 0 43px;
  }
  @include mq(600) {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.coin-list__popular-img {
  width: 5vw;
  height: 5vw;
  margin-bottom: 0.833vw;
  @include mq(1920) {
    width: 96px;
    height: 96px;
    margin-bottom: 16px;
  }
}

.coin-list__popular-name {
  @extend .gilroy-40-extrabold;
}

.coin-list__accepted {
  margin-top: 3.75vw;
  max-width: 72.916vw;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 1.5625vw;
  grid-row-gap: 1.5625vw;
  @include mq(1920) {
    margin-top: 72px;
    max-width: 1400px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  @include mq(1550) {
    grid-template-columns: repeat(8, 1fr);
    max-width: 1115px;
  }
  @include mq(1200) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    max-width: 780px;
  }
  @include mq(830) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    max-width: 490px;
    margin-top: 50px;
  }
  @include mq(550) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 360px;
  }
  @include mq(400) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    max-width: 250px;
  }
}

.coin-list__accepted-item {
  background: $grey-coin;
  border-radius: 0.4166vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.885vw;
  height: 5.885vw;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  &:hover
  {
    transform: scale(110%);
    -webkit-transform: scale(110%);
    -moz-transform: scale(110%);
    -ms-transform: scale(110%);
    -o-transform: scale(110%);
  }
  @include mq(1920)
  {
    border-radius: 8px;
    width: 113px;
    height: 113px;
  }
}

.coin-list__accepted-icon {
  width: 3.333vw;
  height: 3.333vw;
  @include mq(1920) {
    width: 64px;
    height: 64px;
  }
}

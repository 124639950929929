@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Black.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Extrabold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Light.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Heavy.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLight.woff2") format("woff2"),
    url("../public/fonts/Gilroy-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Thin.woff2") format("woff2"),
    url("../public/fonts/Gilroy-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Black.woff2") format("woff2"),
    url("../public/fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Bold.woff2") format("woff2"),
    url("../public/fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Medium.woff2") format("woff2"),
    url("../public/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../public/fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Thin.woff2") format("woff2"),
    url("../public/fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Light.woff2") format("woff2"),
    url("../public/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Gilroy

.gilroy-90-extrabold {
  font-family: "Gilroy";
  font-size: 4.6875vw;
  line-height: 118%;
  font-style: normal;
  font-weight: 800;
  @include mq(1440) {
    font-size: 70px;
  }
  @include mq(1250) {
    font-size: 55px;
  }
  @include mq(767) {
    font-size: 40px;
  }
  @include mq(500) {
    font-size: 35px;
  }
  @include mq(450) {
    font-size: 30px;
  }
}

.gilroy-70-extrabold {
  font-family: "Gilroy";
  font-size: 3.6458vw;
  line-height: 118%;
  font-style: normal;
  font-weight: 800;
  @include mq(1440) {
    font-size: 50px;
  }
  @include mq(1250) {
    font-size: 40px;
  }
  @include mq(767) {
    font-size: 30px;
  }
  @include mq(450) {
    font-size: 25px;
  }
}

.gilroy-60-extrabold {
  font-family: "Gilroy";
  font-size: 3.125vw;
  line-height: 115%;
  font-style: normal;
  font-weight: 800;
  @include mq(1920) {
    font-size: 60px;
  }
  @include mq(1250) {
    font-size: 45px;
  }
  @include mq(767) {
    font-size: 35px;
  }
  @include mq(450) {
    font-size: 25px;
  }
}

.gilroy-50-extrabold {
  font-family: "Gilroy";
  font-size: 2.6vw;
  line-height: 120%;
  font-style: normal;
  font-weight: 800;
  @include mq(1920) {
    font-size: 50px;
  }
  @include mq(1250) {
    font-size: 40px;
  }
  @include mq(767) {
    font-size: 30px;
  }
  @include mq(450) {
    font-size: 20px;
  }
}

.gilroy-40-extrabold {
  font-family: "Gilroy";
  font-size: 2.0833vw;
  line-height: 120%;
  font-style: normal;
  font-weight: 800;
  @include mq(1920) {
    font-size: 40px;
  }
  @include mq(1250) {
    font-size: 32px;
  }
  @include mq(767) {
    font-size: 24px;
  }
  @include mq(450) {
    font-size: 18px;
  }
}
.gilroy-40-600 {
  font-family: "Gilroy";
  font-size: 2.0833vw;
  line-height: 120%;
  font-style: normal;
  font-weight: 600;
  @include mq(1920) {
    font-size: 40px;
  }
  @include mq(1250) {
    font-size: 32px;
  }
  @include mq(767) {
    font-size: 24px;
  }
  @include mq(450) {
    font-size: 18px;
  }
}
.gilroy-30-extrabold {
  font-family: "Gilroy";
  font-size: 1.5625vw;
  line-height: 118%;
  font-style: normal;
  font-weight: 800;
  @include mq(1440) {
    font-size: 23px;
  }
}

.gilroy-24-extrabold {
  font-family: "Gilroy";
  font-size: 1.25vw;
  line-height: 118%;
  font-style: normal;
  font-weight: 800;
  @include mq(1440) {
    font-size: 18px;
  }
}
.gilroy-20-extrabold {
  font-family: "Gilroy";
  font-size: 1vw;
  line-height: 118%;
  font-style: normal;
  font-weight: 800;
  @include mq(1440) {
    font-size: 16px;
  }
}

// Roboto

.roboto-150-900 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 7.8125vw;
  line-height: 69%;
  @include mq(1920) {
    font-size: 150px;
  }
  @include mq(1440) {
    font-size: 120px;
  }
  @include mq(1100) {
    font-size: 100px;
  }
  @include mq(900) {
    font-size: 80px;
  }
  @include mq(700) {
    font-size: 60px;
  }
  @include mq(550) {
    font-size: 50px;
  }
  @include mq(400) {
    font-size: 50px;
  }
}

.roboto-24-normal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 1.25vw;
  line-height: 150%;
  @include mq(1440) {
    font-size: 18px;
  }
  @include mq(767) {
    font-size: 16px;
  }
}
.roboto-24-500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 150%;
  @include mq(1440) {
    font-size: 18px;
  }
  @include mq(767) {
    font-size: 16px;
  }
}

.roboto-20-normal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 150%;
  @include mq(1440) {
    font-size: 16px;
  }
}

.roboto-20-500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 100%;
  @include mq(1440) {
    font-size: 14px;
  }
}

.roboto-20-700 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 100%;
  @include mq(1440) {
    font-size: 16px;
  }
}

.roboto-24-400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 150%;
  @include mq(1440) {
    font-size: 18px;
  }
  @include mq(767) {
    font-size: 16px;
  }
}

.roboto-24-bold {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 1.25vw;
  line-height: 150%;
  @include mq(1440) {
    font-size: 18px;
  }
  @include mq(767) {
    font-size: 16px;
  }
}

.roboto-22-500 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1458vw;
  line-height: 100%;
  @include mq(1440) {
    font-size: 18px;
  }
  @include mq(767) {
    font-size: 16px;
  }
}

.roboto-18-normal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375vw;
  line-height: 150%;
  @include mq(1920) {
    font-size: 18px;
  }
  @include mq(1770) {
    font-size: 16px;
  }
}

.roboto-14-normal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 0.729166vw;
  line-height: 100%;
  @include mq(1920) {
    font-size: 14px;
  }
}

.roboto-18-400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375vw;
  line-height: 150%;
  @include mq(1920) {
    font-size: 18px;
  }
  @include mq(1770) {
    font-size: 16px;
  }
}

.footer__top {
  background: $grey-bg;
  padding: 5.36458vw 6.25vw 4.27vw;
  display: flex;
  justify-content: space-between;

  @include mq(1920) {
    padding: 103px 120px 82px;
  }

  @include mq(1375) {
    padding: 80px;
  }
  @include mq(1300) {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-row-gap: 50px;
    padding: 80px 200px;
  }

  @include mq(1150) {
    padding: 80px 100px;
  }
  @include mq(900) {
    padding: 80px 50px;
  }
  @include mq(800) {
    grid-template-columns: auto auto;
  }
  @include mq(700) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 0px;
  }
  @include mq(450) {
    padding: 50px 10px;
  }
}

.footer__bottom {
  @extend .roboto-18-400;
  background: $green-bg;
  color: $white;
  padding: 1vw 0.5vw;
  text-align: center;

  @include mq(1920) {
    padding: 20px 10px;
  }
}

.footer__logo {
  width: 10.5729vw;
  margin-bottom: 1vw;
  @include mq(1920) {
    // width: 203px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.footer__text {
  @extend .roboto-18-normal;
  color: $grey-nav;
  margin-bottom: 1.5vw;
  @include mq(1920) {
    margin-bottom: 30px;
  }
}

.share__list {
  display: flex;
  @include mq(700) {
    justify-content: center;
  }
}

.footer__title {
  @extend .roboto-24-bold;
  color: $white;
  margin-bottom: 1.25vw;
  @include mq(1920) {
    margin-bottom: 24px;
  }
}

.share__list-link {
  background: $black-bg;
  will-change: background;
  transition: 0.3s;
  width: 2.1875vw;
  height: 2.1875vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.52vw;
  border-radius: 0.26vw;
  border: 0.104vw solid $grey-light;

  &:hover {
    background: $green-bg;
  }

  @include mq(1920) {
    margin-right: 10px;
    width: 42px;
    height: 42px;
    border-radius: 5px;
    border: 2px solid $grey-light;
  }
  @include mq(700) {
    margin: 10px;
  }
}

.footer__list-link {
  @extend .roboto-18-normal;
  display: inline-block;
  margin-bottom: 1vw;
  text-decoration: none;
  color: $grey-light;
  transition: 0.3s;
  will-change: color;

  &:hover {
    color: $green-bg;
  }

  @include mq(1920) {
    margin-bottom: 20px;
  }
}

.footer__one {
  width: 14.9vw;

  & .footer__title {
    margin-bottom: 1vw;
    @include mq(1920) {
      margin-bottom: 19px;
    }
  }

  @include mq(1920) {
    width: 285px;
  }
  @include mq(700) {
    width: 285px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.footer__two {
  width: 10.2vw;
  @include mq(1920) {
    width: 200px;
  }
  @include mq(700) {
    width: 285px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.footer__three {
  width: 7.65vw;
  @include mq(1920) {
    width: 146px;
  }
  @include mq(700) {
    width: 285px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.footer__four {
  width: 14.1vw;
  & .footer__text {
    color: $grey-light;
  }
  @include mq(1920) {
    width: 266px;
  }
  @include mq(700) {
    width: 285px;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.footer__five {
  width: 8.6vw;
  @include mq(1920) {
    width: 165px;
  }
  @include mq(700) {
    width: 285px;
    max-width: 100%;
    text-align: center;
  }
}

.domain-dashboard__box {
  max-width: 66.145vw;
  display: flex;
  justify-content: space-between;
  margin: 4.16666vw auto 4.687vw;

  @include mq(1920) {
    max-width: 1270px;
    margin: 80px auto 90px;
  }
  @include mq(1420) {
    max-width: 1070px;
  }
  @include mq(1250) {
    max-width: 950px;
    margin: 70px auto 80px;
  }
  @include mq(1000) {
    flex-direction: column;
    align-items: center;
  }
}

.domain-dashboard {
  text-align: center;
}

.domain-dashboard__image {
  margin-bottom: 5.9895vw;
  width: 28.125vw;

  @include mq(1920) {
    margin-bottom: 115px;
    width: 540px;
    max-width: 100%;
  }
  @include mq(767) {
    margin-bottom: 80px;
    width: 440px;
    max-width: 90%;
  }
}

.domain-dashboard__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18.229vw;
  @include mq(1920) {
    width: 350px;
  }
  @include mq(1250) {
    width: 280px;
  }
  @include mq(1000) {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mq(450) {
    width: 250px;
  }
}

.domain-dashboard__item-top {
  width: 6.25vw;
  height: 6.25vw;
  background: $green-light;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4166vw;
  @include mq(1920) {
    height: 120px;
    width: 120px;
    border-radius: 8px;
  }
}

.domain-dashboard__item-icon {
  width: 2.395833vw;

  @include mq(1920) {
    width: 46px;
  }
}

.domain-dashboard__item-heading {
  @extend .gilroy-40-extrabold;
  margin: 1.25vw 0 0.83333vw;
  @include mq(1920) {
    margin: 24px 0 16px;
  }
  @include mq(767) {
    font-size: 32px;
  }
  @include mq(450) {
    font-size: 32px;
  }
}

.domain-dashboard__item-paragraph {
  @extend .roboto-18-normal;
  color: $grey-nav;
  text-align: center;
}

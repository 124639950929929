.blog {
  background: #EDEDED;
  padding: 45px 0;
  a {
    outline:0;
    text-decoration: none;
  }
  &__row{
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 1280px;
    width: 100%;
  }
  article {
    background-color: #fff;
    border-radius:6px;
    margin-bottom: 40px;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    -ms-border-radius:6px;
    -o-border-radius:6px;
    .post-content{
      padding:25px; 
      p {
        font-size: 1.0625rem;
        font-weight: 300;
        line-height: 1.45;
        margin: 0 0 1.0625rem;
        font-family: "Hind", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
        color:#5a5a5a;
      }
    }
    hr{
      border: solid #DE6262;
      border-width: 1px 0 0;
      clear: both;
      height: 0;
      margin-top: 27px;
      margin-bottom: 27px;
      max-width: 70px;
    }
  }

  &__title{
    font-size: 32px;
    letter-spacing: -.07em;
    color: #5a5a5a;
    font-family: "Montserrat", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 12px;
    margin-top: 12px;
    text-rendering: optimizelegibility;
    a{
      color: #6E6E6E;
    }
  }
  &__date{
    color: #abaaaa;
    font-size: 19px;
    font-weight: 900;
    font-family: "Montserrat", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
  }
  &__alert{
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
    font-family: "Montserrat", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
    &-success{
      background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
      background-repeat: repeat-x;
      border-color: #b2dba1;
      color: #3c763d;
      font-size: 0.875rem;
      a {
        color: #DE6262;
        font-family: inherit;
        font-size: 0.875rem;
      }
    }
  }
  &__author-wrap{
    position: relative;
    overflow: hidden;
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 50px;
  }
  &__author-gravatar{
    margin: 0;
    line-height: 1;
  }
  &__author-title{
    color: #6E6E6E;
    display: block;
    line-height: 1.5;
    margin: 0 0 15px;
    padding: 0 0 10px;
    border-bottom: 1px solid #DE6262;
    h6{
      padding: 0;
      margin: 0 0 6px;
      font-size: 19px;
      font-weight: 700;
      font-family: "Montserrat", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
    }
  }
  &__author-description{
    display: block;
    margin: 0 0 5px;
    p{
      margin: 0;
      font-size: 17px;
      font-weight: 300;
      font-style: normal;
      font-family: "Hind", Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
      line-height: 1.45;
      color:#5a5a5a;
    }
    a {
      color: #DE6262;
      outline: 0;
      text-decoration: none;
    }
  }
}


.home-banner {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 100vh;

  margin-top: -7.1875vw;

  @include mq(1920) {
    margin-top: -138px;
  }
  @include mq(1375) {
    margin-top: -98px;
  }
  @include mq(767) {
    margin-top: -75px;
  }
  @include mq(500) {
    margin-top: -70px;
  }
}

.home-banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-banner-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 1s opacity;
  z-index: 2;

  &.active {
    opacity: 1;
  }
}

.active {
  .home-banner-bg {
    z-index: 1;
    opacity: 1;
    transition: 0.3s opacity;
  }
}

.home-banner__nav {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  animation: 0.3s fadeIn forwards;
}

.home-banner__item {
  @extend .gilroy-20-extrabold;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s background;
  width: 25%;
  padding: 4.2187vw 0;
  color: $grey-nav;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  cursor: pointer;

  &.active {
    background: rgba(0, 0, 0, 0.7);
    .home-banner__item-number {
      color: $white;
    }
    .home-banner__item-name {
      color: $green-light;
    }
  }
  &:hover {
    .home-banner__item-number {
      color: $white;
    }
    .home-banner__item-name {
      color: $green-light;
    }
  }
  @include mq(1920) {
    padding: 81px 0;
  }
  @include mq(1440) {
    padding: 61px 0;
  }
  @include mq(767) {
    padding: 50px 0;
  }
}

.home-banner__item-number {
  @extend .gilroy-20-extrabold;
  transition: 0.3s;
  position: absolute;
  top: 2.083333vw;
  left: 6.25vw;
  @include mq(1920) {
    top: 40px;
    left: 50%;
    transform: translateX(-90px);
  }
  @include mq(1250) {
    transform: translateX(-70px);
  }
  @include mq(767) {
    display: none;
  }
}

.home-banner__item-name {
  transition: 0.3s color;
  @include mq(767) {
    transform: rotate(-90deg);
    transform-origin: center center;
  }
}

.home-banner__content {
  position: absolute;
  top: 16.4vw;
  left: 13.5416vw;
  width: 80%;
  @include mq(1920) {
    left: 260px;
    top: 35%;
  }
  @include mq(1440) {
    left: 180px;
    top: 180px;
  }
  @include mq(1250) {
    left: 120px;
    top: 30%;
  }
  @include mq(767) {
    left: 50px;
    top: 25%;
  }
  @include mq(500) {
    left: 30px;
    top: 150px;
  }
  @include mq(450) {
    left: 20px;
  }
}

.home-banner__content-slide {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: 0.3s;
  pointer-events: none;
  width: 80%;
  will-change: opacity, transform;

  &.active {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    z-index: 2;
    pointer-events: all;
  }
  @include mq(767) {
    width: 100%;
  }
}

.home-banner__content-heading {
  @extend .gilroy-90-extrabold;
  margin: 0;
  color: $white;
  text-transform: uppercase;
}

.home-banner__content-subheading {
  @extend .roboto-24-normal;
  color: $white;
}

.home-banner__content-link {
  @extend .roboto-20-normal;
  text-decoration: none;
  display: inline-block;
  background: $green-light;
  transition: 0.3s;
  color: $white;
  padding: 0.9375vw 1.30208333vw;
  border-radius: 0.416666vw;
  margin-top: 1.25vw;
  &:hover {
    background: $green;
  }
  @include mq(1920) {
    padding: 18px 25px;
    border-radius: 8px;
    margin-top: 24px;
  }
  @include mq(1440) {
    padding: 12px 25px;
    border-radius: 8px;
    margin-top: 24px;
  }
}

.coming-soon {
  padding: 2vw 50px;
  @include mq(1920) {
    padding: 30px 150px 50px;
  }
  @include mq(1900) {
    padding: 20px 100px 50px;
  }
  @include mq(1400) {
    padding: 20px 50px 30px;
  }
  @include mq(767) {
    padding: 20px 10px 30px;
  }
  &__container {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    h3 {
      font-family: "Gilroy";
      font-size: 5rem;
      text-align: center;
      color: $green;
    }
  }
}

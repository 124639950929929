.hosting-cards {
  padding-top: 7.291666vw;
  padding-bottom: 9.375vw;
  @include mq(1920) {
    padding-bottom: 180px;
    padding-top: 140px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @include mq(767) {
    padding-bottom: 80px;
    padding-top: 50px;
  }
}

.hosting-cards__nav-title {
  @extend .roboto-24-normal;
  text-align: center;
  padding: 4.7395vw 0 1.25vw;
  @include mq(1920) {
    padding: 91px 0 24px;
  }
  @include mq(767) {
    padding: 50px 0 24px;
  }
}

.hosting-cards__nav {
  width: 31.71875vw;
  background: #f3f3f3;
  border-radius: 0.4166vw;
  margin: 0 auto 4.6875vw;
  display: flex;
  @include mq(1920) {
    border-radius: 8px;
    width: 609px;
    margin: 0 auto 90px;
  }
  @include mq(1070) {
    background: transparent;
    flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  @include mq(767) {
    margin: 0 auto 50px;
  }
}

.hosting-cards__nav-item {
  @extend .roboto-24-normal;
  width: 10.57291667vw;
  text-align: center;
  background: #f3f3f3;
  border-radius: 0.4166vw;
  padding: 1.197916vw 0;
  color: #b7b7b7;
  cursor: pointer;
  transition: 0.3s;

  &.active {
    background: #90d5ca;
    color: $green;
  }
  &:hover {
    background: #90d5ca;
    color: $green;
  }
  @include mq(1920) {
    border-radius: 8px;
    width: 203px;
    padding: 23px 0;
  }
  @include mq(1070) {
    margin: 5px;
  }
  @include mq(767) {
    padding: 15px;
    width: 150px;
  }
  @include mq(520) {
    padding: 10px;
    width: 100px;
  }
}

.hosting-cards__container {
  position: relative;
  width: 47.34375vw;
  margin: 0 auto;
  @include mq(1920) {
    width: 909px;
    max-width: 100%;
  }
}

.hosting-cards__container--reseller {
  .hosting-cards__box {
    display: flex;
    justify-content: center;
  }
}

.hosting-cards__box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(20px);

  &.active {
    display: flex;
    position: relative;
    z-index: 2;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }

  @include mq(1000) {
    flex-direction: column;
    align-items: center;

    & .card {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include mq(480) {
    width: 100%;
  }
}

.card {
  width: 22.86458333vw;
  background: $green-bg;
  color: $white;
  display: flex;
  flex-direction: column;
  border-radius: 0.4166vw;
  padding: 1.25vw 2.1354166vw 2.3958333vw;
  @include mq(1920) {
    padding: 24px 41px 46px;
    border-radius: 8px;
    width: 439px;
  }
  @include mq(485) {
    padding: 12px 20px 36px;
    width: 100%;
  }
}

.card__link {
  @extend .roboto-20-500;
  background: $white;
  transition: 0.3s;
  text-decoration: none;
  border-radius: 0.4166vw;
  width: 100%;
  display: block;
  text-align: center;
  color: $green;
  padding: 0.9375vw 0;
  margin-top: 2.13541vw;

  &:hover {
    color: $white;
    background: $green;
  }
  @include mq(1920) {
    margin-top: 41px;
    padding: 18px 0;
    border-radius: 8px;
  }
  @include mq(485) {
    margin-top: 20px;
  }
}

.card__top {
  display: flex;
  margin-bottom: 1vw;
  @include mq(1920) {
    margin-bottom: 20px;
  }
}

.card__title {
  @extend .gilroy-40-600;
  margin: 0 0 0.625vw;
  @include mq(1920) {
    margin: 0 0 12px;
  }
}

.card__price {
  @extend .gilroy-40-600;
  line-height: 100%;
  margin-right: 0.72916666vw;
  @include mq(1920) {
    margin-right: 0 0 14px;
  }
  @include mq(767) {
    font-size: 32px;
  }
  @include mq(450) {
    font-size: 32px;
  }
}

.card__deal {
  @extend .roboto-18-normal;
  align-self: flex-end;
}

.card__list-item {
  @extend .roboto-18-normal;
  display: flex;
  align-items: center;
  margin-bottom: 0.9375vw;
  @include mq(1920) {
    margin-bottom: 18px;
  }
  @include mq(485) {
    margin-bottom: 12px;
    font-size: 14px;
  }
}

.card__list__checkmark {
  width: 1.25vw;
  height: 1.25vw;
  margin-right: 0.8333vw;
  @include mq(1920) {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}

.card__period {
  @extend .roboto-20-500;
  background: $white;
  text-decoration: none;
  border-radius: 0.4166vw;
  display: inline-block;
  text-align: center;
  color: $green;
  padding: 0.9375vw 2.5vw;
  margin-bottom: 0.88541vw;
  margin-left: auto;
  transform: translateX(0.88541vw);

  @include mq(1920) {
    padding: 18px 48px;
    border-radius: 8px;
    margin-bottom: 17px;
    transform: translateX(17px);
  }
  @include mq(485) {
    transform: translateX(8px);
  }
}

.cards {
  padding: 6.25vw 0;
  @include mq(1920) {
    padding: 120px 0;
  }
  @include mq(1440) {
    padding: 90px 0;
  }
  @include mq(767) {
    padding: 50px 10px;
  }
}

.cards__box {
  display: flex;
  justify-content: space-between;
  margin: 3.3333vw auto 0;
  max-width: 71.875vw;

  .card {
    padding-top: 3.125vw;
    @include mq(1920) {
      padding-top: 60px;
    }
    @include mq(1380) {
      margin: 25px;
    }
    @include mq(767) {
      margin: 15px 0;
    }
  }

  @include mq(1920) {
    margin: 64px auto 0;
    max-width: 1380px;
  }
  @include mq(1440) {
    max-width: 1350px;
    flex-wrap: wrap;
  }
  @include mq(1380) {
    max-width: 1250px;
    justify-content: center;
  }
  @include mq(767) {
    margin: 30px auto 0;
  }
}

//base

@import "./base/normalize";
@import "./base/mixins";
@import "./base/fonts";
@import "./base/variables";
@import "./base/animations";
@import "./base/base";
@import "./base/utils";

//components

@import "./components/select";
@import "./components/banner";
@import "./components/header";
@import "./components/footer";
@import "./components/banner";
@import "./components/home-banner";
@import "./components/home-icons";
@import "./components/accordion";
@import "./components/hosting-cards";
@import "./components/domain-table";
@import "./components/servers-info";
@import "./components/founded";
@import "./components/build";
@import "./components/servers-table";
@import "./components/domain-dashboard";
@import "./components/text-form";
@import "./components/coin-list";
@import "./components/bg-image";
@import "./components/questions";
@import "./components/culture";
@import "./components/data-centers";
@import "./components/payment-methods";
@import "./components/contact-form";
@import "./components/login";
@import "./components/coming-soon";
@import "./components/blog";

.servers-table {
  padding: 7.2916vw 10px 4.1666666vw;

  & .heading {
    max-width: 62.5vw;
    margin-left: auto;
    margin-right: auto;
    @include mq(1920) {
      max-width: 1200px;
    }
  }

  & .subheading {
    max-width: 54.1666vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4.6875vw;
    @include mq(1920) {
      max-width: 1040px;
      margin-bottom: 90px;
    }
    @include mq(1440) {
      margin-bottom: 70px;
    }
    @include mq(767) {
      margin-bottom: 50px;
    }
  }
  @include mq(1920) {
    padding: 140px 10px 80px;
  }
  @include mq(1440) {
    padding: 100px 10px 60px;
  }
  @include mq(767) {
    padding: 30px 10px 10px;
  }
}

.servers-table__label {
  @extend .gilroy-50-extrabold;
  text-align: center;
  color: $green;
  margin-bottom: 2.6vw;
  @include mq(1920) {
    margin-bottom: 48px;
  }
  @include mq(450) {
    font-size: 25px;
  }
}

.servers-table__box {
  max-width: 87.5vw;
  margin: 0 auto;

  @include mq(1920) {
    max-width: 1680px;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th {
    @extend .roboto-20-700;
    text-align: center;
    padding-bottom: 0.677vw;
    border-bottom: 1px solid $grey-bg;
    @include mq(1920) {
      padding-bottom: 13px;
    }
  }

  & td {
    @extend .roboto-20-normal;
    color: $grey-nav;
    line-height: 160%;
    text-align: center;
    padding: 0.8333vw 0;
    border-bottom: 1px solid $grey-bg;

    &:first-child {
      color: $green;
      a {
        color: $green;
        text-decoration: none;
      }
    }
    @include mq(1920) {
      padding: 16px 0;
    }
    @include mq(767) {
      padding: 8px 0;
    }
  }

  @include mq(1400) {
    width: 1380px;
    position: relative;
    will-change: left;
  }
}

.table__btn {
  @extend .roboto-14-normal;
  color: $white;
  background: $green;
  justify-content: space-between;
  will-change: background-color;
  display: flex;
  align-self: center;
  width: 9.010416667vw;
  margin-left: auto;
  padding: 0.52vw 1.2vw;
  border-radius: 0.52vw;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: $green-bg;
  }
  @include mq(1920) {
    padding: 10px 22px;
    width: 173px;
    border-radius: 5px;
  }
}

.server-table__button {
  @extend .roboto-22-500;
  color: $white;
  line-height: 100%;
  display: block;
  width: 100%;
  text-decoration: none;
  border: none;
  background: $green-light;
  padding: 0.8333vw 0;
  margin-top: 0.8333vw;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 0.4166vw;
  text-align: center;

  &:hover {
    background: $green;
  }

  @include mq(1920) {
    padding: 16px 0;
    margin-top: 16px;
    border-radius: 8px;
  }
}

.table-heading {
  @extend .gilroy-50-extrabold;
  color: $green;
  max-width: 1680px;
  margin: 0 auto 25px;
  @include mq(450) {
    font-size: 30px;
  }
  @include mq(1921, min) {
    max-width: 87.5vw;
    margin: 0 auto 1.302083333vw;
  }
}

.table-heading-2 {
  margin-top: 64px;
  @include mq(767) {
    margin-top: 50px;
  }
  @include mq(1921,min) {
    margin-top: 3.333333333vw;
  }
}
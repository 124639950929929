body,
html {
  overflow-x: hidden;
}

* {
  &::selection {
    background: #60cfc2;
  }
}

.main {
  padding-top: 8.5vw;
  transition: 0.3s;
  will-change: filter;

  &.blur {
    filter: blur(2px);
  }

  @include mq(1920) {
    padding-top: 150px;
  }
  @include mq(1375) {
    padding-top: 125px;
  }
  @include mq(767) {
    padding-top: 85px;
  }
  @include mq(500) {
    padding-top: 85px;
  }
}

.fade-in {
  opacity: 0;
  transform: translateY(30px);
  transition: 0.3s;
  will-change: opacity, transform;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.heading {
  @extend .gilroy-70-extrabold;
  text-align: center;
  margin: 0;
}

.mb-8 {
  margin-bottom: 0.416666vw;
  @include mq(1920) {
    margin-bottom: 8px;
  }
}

.mb-24 {
  margin-bottom: 1.25vw;
  @include mq(1920) {
    margin-bottom: 24px;
  }
}

.subheading {
  @extend .roboto-24-400;
  text-align: center;
  color: $grey-nav;
}

.accordion__nav {
  width: 70.8854166vw;
  background: #f3f3f3;
  border-radius: 0.4166vw;
  margin: 4.6875vw auto 8.125vw;
  display: flex;
  @include mq(1920) {
    border-radius: 8px;
    width: 1361px;
    margin: 90px auto 156px;
  }
  @include mq(1500) {
    width: 1250px;
  }
  @include mq(1350) {
    width: 950px;
    margin: 90px auto 106px;
  }
  @include mq(1070) {
    flex-wrap: wrap;
    justify-content: center;
    width: 630px;
    background: $white;
    max-width: 100%;
    margin: 90px auto 106px;
  }
  @include mq(767) {
    margin: 50px auto 50px;
  }
}

.accordion {
  overflow: hidden;
  @include mq(1500) {
    padding: 0 50px;
  }
  @include mq(767) {
    padding: 0 10px;
  }
}

.accordion__box-top-right {
  max-width: 100%;
}

.accordion__nav-item {
  @extend .roboto-24-normal;
  width: 15.625vw;
  text-align: center;
  background: #f3f3f3;
  border-radius: 0.4166vw;
  padding: 1.197916vw 0;
  color: #b7b7b7;
  cursor: pointer;
  transition: 0.3s;

  &.active {
    background: #90d5ca;
    color: $green;
  }
  &:hover {
    background: #90d5ca;
    color: $green;
  }
  @include mq(1920) {
    border-radius: 8px;
    width: 300px;
    padding: 23px 0;
  }
  @include mq(1500) {
    width: 250px;
  }
  @include mq(1350) {
    width: 190px;
  }
  @include mq(1070) {
    margin: 5px;
  }
  @include mq(767) {
    padding: 15px;
  }
  @include mq(520) {
    padding: 10px;
  }
}

.accordion__container {
  width: 70.8854166vw;
  margin: 0 auto 6.6145vw;
  padding-left: 7.291666vw;
  @include mq(1920) {
    width: 1361px;
    margin: 0 auto 127px;
    padding-left: 140px;
  }
  @include mq(1600) {
    padding-left: 0;
  }
  @include mq(1500) {
    width: 1250px;
    max-width: 100%;
  }
  @include mq(767) {
    margin-bottom: 50px;
  }
}

.accordion__box-top {
  text-align: left;
  display: flex;
  margin-bottom: 3.4375vw;
  @include mq(1920) {
    margin-bottom: 74px;
  }
  @include mq(1250) {
    flex-direction: column;
    align-items: center;
  }
  @include mq(767) {
    margin-bottom: 50px;
  }
}

.accordion__box-top-left {
  min-width: 8.33333vw;
  height: 8.33333vw;
  border-radius: 0.41666vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4.6875vw;
  background: $green-light;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  @include mq(1920) {
    min-width: 160px;
    height: 160px;
    border-radius: 8px;
    margin-right: 90px;
  }
  @include mq(1250) {
    margin-right: 0;
  }
  @include mq(767) {
    min-width: 80px;
    height: 80px;
  }
}

.accordion__box-top-icon {
  height: 5vw;
  width: 5vw;
  @include mq(1920) {
    height: 96px;
    width: 96px;
  }
  @include mq(767) {
    min-width: 50px;
    height: 50px;
  }
}

.accordion__box-heading {
  @extend .gilroy-50-extrabold;
  margin: 1.7187vw 0 1.25vw;
  @include mq(1920) {
    margin: 33px 0 24px;
  }
  @include mq(1500) {
    font-size: 40px;
  }
  @include mq(1250) {
    text-align: center;
    font-size: 35px;
  }
  @include mq(767) {
    text-align: center;
    font-size: 25px;
  }
}

.accordion__box-text {
  @extend .roboto-18-400;
  color: $grey-nav;
  @include mq(1250) {
    text-align: center;
  }
}

.accordion__box-one-img {
  width: 37.5vw;
  @include mq(1920) {
    width: 720px;
    max-width: 100%;
  }
}
.accordion__box-five-img {
  width: 41.6666vw;
  @include mq(1920) {
    width: 800px;
    max-width: 100%;
  }
}

.accordion__box {
  text-align: center;
  width: calc(100% - 7.291666vw);
  opacity: 0;
  transform: translateY(20px);
  position: absolute;
  top: 0;
  left: 7.291666vw;
  transition: 0.3s opacity, 0.3s transform;

  &.active {
    text-align: center;
    width: 100%;
    z-index: 2;
    opacity: 1;
    position: relative;
    transform: translateY(0);
    transition-delay: 0.2s;
    left: 0;
    @include mq(1920) {
      width: 100%;
    }
  }
  @include mq(1920) {
    left: 140px;
    width: calc(100% - 140px);
  }
  @include mq(1600) {
    left: 0;
    width: 100%;
  }
}

.accordion__box-heading--one {
  max-width: 43.229vw;
  @include mq(1920) {
    max-width: 830px;
  }
  @include mq(1500) {
    max-width: 725px;
  }
}

.accordion__box-text--one {
  max-width: 44.229vw;
  @include mq(1920) {
    max-width: 840px;
  }
  @include mq(1500) {
    max-width: 725px;
  }
}

.accordion__box-heading--relax {
  width: 52.708333vw;
  @include mq(1920) {
    width: 1012px;
  }
  @include mq(1500) {
    max-width: 840px;
    max-width: 100%;
  }
}

.accordion__box-text--relax {
  width: 52.908333vw;
  @include mq(1920) {
    width: 1022px;
  }
  @include mq(1500) {
    max-width: 840px;
    max-width: 100%;
  }
}

.accordion__box-heading--soft {
  width: 38.906vw;
  @include mq(1920) {
    width: 747px;
  }
  @include mq(1500) {
    max-width: 640px;
    max-width: 100%;
  }
}

.accordion__box-text--soft {
  width: 41.6666vw;
  @include mq(1920) {
    width: 800px;
    max-width: 100%;
  }
}

.accordion__box-heading--superion {
  width: 38.906vw;
  @include mq(1920) {
    width: 747px;
  }
  @include mq(1500) {
    max-width: 660px;
    max-width: 100%;
  }
}

.accordion__box-text--superion {
  width: 41.6666vw;
  @include mq(1920) {
    width: 800px;
    max-width: 100%;
  }
}
.accordion__box-text--99 {
  width: 42.6666vw;
  @include mq(1920) {
    width: 820px;
    max-width: 100%;
  }
}

.accordion__box-item {
  display: flex;
  margin-bottom: 4.375vw;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq(1920) {
    margin-bottom: 84px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mq(767) {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.accordion__box-item-left {
  min-width: 8.33333vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 4.6875vw;
  @include mq(1920) {
    min-width: 160px;
    margin-right: 90px;
  }
  @include mq(900) {
    min-width: 50px;
    margin-right: 50px;
  }
  @include mq(767) {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.accordion__box-item-heading {
  @extend .gilroy-40-extrabold;
  text-align: left;
  margin: 0;
  @include mq(767) {
    text-align: center;
  }
}

.accordion__box-item-text {
  @extend .roboto-18-400;
  text-align: left;
  color: $grey-nav;
  max-width: 34.8958vw;
  margin-top: 0.83333333vw;
  @include mq(1920) {
    max-width: 670px;
    margin-top: 16px;
  }
  @include mq(767) {
    text-align: center;
  }
}

.ddos {
  width: 7.1875vw;
  @include mq(1920) {
    width: 138px;
  }
}

.ab {
  width: 8.385416vw;
  @include mq(1920) {
    width: 161px;
  }
}

.cah {
  width: 7.864583vw;
  @include mq(1920) {
    width: 151px;
  }
}

.accordion__box-items--softacolous {
  .accordion__box-item-left {
    transform: translate(2.3437vw, -0.7812vw);
    @include mq(767) {
      transform: none;
    }
  }

  .accordion__box-item-text {
    max-width: 34.21875vw;

    @include mq(1920) {
      max-width: 657px;
    }
  }

  .accordion__box-item-left {
    & img {
      width: 8.3333333vw;
      height: 8.3333333vw;
      @include mq(1920) {
        width: 160px;
        height: 160px;
      }
    }
  }
}

.accordion__box--unbeatable {
  .accordion__box-item {
    align-items: center;
  }
  .accordion__box-item-left {
    justify-content: flex-end;
  }
  .accordion__box-item-left-inner {
    background: $green-light;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.25vw;
    height: 6.25vw;
    border-radius: 0.4166vw;
    @include mq(1920) {
      width: 120px;
      height: 120px;
      border-radius: 8px;
    }
  }

  .accordion__box-item-left-inner img {
    width: 3.125vw;
    height: 3.125vw;
    @include mq(1920) {
      width: 60px;
      height: 60px;
    }
  }
}

.banner {
  position: relative;
  z-index: 1;
  padding: 9.89vw 50px;
  @include mq(1440) {
    padding: 120px 50px;
  }
  @include mq(767) {
    padding: 60px 10px;
  }
}

.banner-picture {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.banner-bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner--data-center {
  & .banner__text {
    max-width: 60.6354vw;
    text-align: center;
    margin: 0 auto;

    @include mq(1440) {
      max-width: 859px;
    }
    @include mq(1250) {
      max-width: 810px;
    }
    @include mq(767) {
      max-width: 575px;
    }
  }
}

.banner--about {
  & .banner__text {
    max-width: 50.6354vw;
    text-align: center;
    margin: 0 auto;

    @include mq(1440) {
      max-width: 675px;
    }
    @include mq(1250) {
      max-width: 570px;
    }
    @include mq(767) {
      max-width: 430px;
    }
  }
}

.banner--payment {
  margin-bottom: 2.8125vw;

  & .banner__text {
    max-width: 44.79vw;
    text-align: center;
    margin: 0 auto;

    @include mq(1440) {
      max-width: 675px;
    }
    @include mq(1250) {
      max-width: 570px;
    }
    @include mq(767) {
      max-width: 430px;
    }
  }
  @include mq(1920) {
    margin-bottom: 50px;
  }
}

.banner--domains {
  & .banner__text {
    max-width: 44.79vw;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2.1875vw;
    @include mq(1920) {
      margin-bottom: 42px;
    }

    @include mq(1440) {
      max-width: 645px;
    }
    @include mq(1250) {
      max-width: 570px;
    }
    @include mq(767) {
      max-width: 430px;
    }
  }
}

.banner--faq {
  & .banner__heading {
    max-width: 28.125vw;
    text-align: center;
    margin: 0 auto 0.4166vw;

    @include mq(1440) {
      max-width: 400px;
      margin: 0 auto 8px;
    }
    @include mq(1250) {
      max-width: 310px;
    }
    @include mq(767) {
      max-width: 230px;
    }
    @include mq(450) {
      max-width: 200px;
    }
  }

  & .banner__subheading {
    max-width: 60.6354vw;
    text-align: center;
    margin: 0 auto;

    @include mq(1440) {
      max-width: 859px;
    }
    @include mq(1250) {
      max-width: 810px;
    }
    @include mq(767) {
      max-width: 575px;
    }
  }
}

.banner__heading {
  @extend .gilroy-70-extrabold;
  color: $white;
  margin: 0 0 0.4vw;

  @include mq(1920) {
    margin: 0 0 8px;
  }
}

.banner__subheading {
  @extend .roboto-24-normal;
  color: $white;
}

.servers-info {
  display: flex;
  justify-content: space-between;
  padding: 4.322vw 0;
  max-width: 74.4791vw;
  margin: 0 auto;

  @include mq(1920) {
    padding: 83px 0;
    max-width: 1430px;
  }
  @include mq(1530) {
    max-width: 1330px;
    padding: 60px 0;
  }
  @include mq(1370) {
    max-width: 1030px;
  }
  @include mq(1100) {
    max-width: 950px;
  }
  @include mq(1000) {
    flex-direction: column;
    align-items: center;
    padding: 40px 10px 60px;
  }
}

.servers-info__box {
  display: flex;
  max-width: 21.875vw;
  @include mq(1920) {
    max-width: 420px;
  }
  @include mq(1370) {
    max-width: 320px;
  }
  @include mq(1000) {
    margin-bottom: 25px;
    max-width: 420px;
  }
}

.servers-info__box-number {
  @extend .gilroy-20-extrabold;
  color: $green-bg;
  margin-right: 1.3vw;
  @include mq(1920) {
    margin-right: 26px;
  }
  @include mq(450) {
    margin-right: 0;
  }
}

.servers-info__box-heading {
  @extend .gilroy-30-extrabold;
  margin: 1.875vw 0 1.25vw;
  text-indent: 0.52vw;
  @include mq(1920) {
    margin: 36px 0 24px;
    text-indent: 10px;
  }
  @include mq(1000) {
    margin: 24px 0 12px;
    text-indent: 10px;
  }
}

.servers-info__box-text {
  @extend .roboto-18-normal;
  color: $grey-nav;
}

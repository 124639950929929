.contact-us {
  padding: 2vw 50px;
  @include mq(1920) {
    padding: 30px 150px 50px;
  }
  @include mq(1900) {
    padding: 20px 100px 50px;
  }
  @include mq(1400) {
    padding: 20px 50px 30px;
  }
  @include mq(767) {
    padding: 20px 10px 30px;
  }
  &__container {
    display: flex;
    column-gap: 5vw;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    @include mq(991) {
      flex-direction: column;
    }

    &__first-block {
      width: 66.66666667%;
      @include mq(767) {
        width: 100%;
      }

      & > h3 {
        color: $black;
        font-family: "Gilroy";
        font-weight: 700;
        line-height: 1.1;
        margin-bottom: 12px;
        margin-top: 12px;
        text-rendering: optimizelegibility;
        padding: 12px 0;
        font-size: 2vw;
        @include mq(991) {
          font-size: 22px;
        }
        border-bottom: 1px solid #ededed;
        margin-bottom: 25px;
      }
      & > .contact-form {
        & > form {
          display: flex;
          flex-direction: column;
          row-gap: 0.875rem;
          & > div {
            display: flex;
            flex-direction: column;
            row-gap: 0.25rem;

            label,
            input,
            textarea {
              display: block;
              font-family: "Roboto";
              font-size: 1.25rem;
              line-height: 150%;
              @include mq(1440) {
                font-size: 17px;
              }
              @include mq(767) {
                font-size: 16px;
              }
            }
            label {
              font-weight: 300;
              color: $grey-text-dark;
            }
            input:not([type="file"]),
            input:not([type="button"]),
            input:not([type="submit"]),
            textarea {
              padding: 5px 15px;
              line-height: 1.42857143;
              background-color: $white;
              background-image: none;
              border: 1px solid #ccc;
              border-radius: 4px;
              outline: none;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
              box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              -webkit-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              -moz-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              -ms-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
              -o-transition: border-color ease-in-out 0.15s,
                box-shadow ease-in-out 0.15s;
            }
            input[type="text"],
            input[type="password"],
            input[type="date"],
            input[type="datetime"],
            input[type="email"],
            input[type="number"],
            input[type="search"],
            input[type="tel"],
            input[type="time"],
            input[type="url"] {
              height: 55px;
            }

            textarea {
              resize: vertical;
              padding: 15px;
            }
            input[type="submit"] {
              width: max-content;
              background: $green;
              font-weight: 300;
              margin-top: 0;
              color: #fff;
              padding: 9px 19px 10px;
              font-size: 1.2rem;
              border: 0;
              cursor: pointer;
              &:hover {
                background: $grey-text-dark;
              }
              @include mq(1920) {
                font-size: 15px;
              }
              @include mq(991) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &__second-block {
      width: 25%;
      @include mq(767) {
        width: 100%;
      }
      h4.badge {
        padding: 12px;
        display: block;
        font-size: 1.45rem;
        text-align: left;
        background: $green;
        color: $white;
        font-family: "Gilroy";
        font-weight: bold;
        min-height: 10px;
        line-height: 1;
        white-space: nowrap;
        @include mq(1920) {
          font-size: 17px;
        }
        @include mq(991) {
          font-size: 18px;
        }
      }
      p,
      p a {
        font-family: "Gilroy";
        font-size: 1.35rem;
        font-weight: normal;
        line-height: 1.45;
        margin: 0 0 17px 0;
        font-weight: 300;
        color: $grey-text-dark;
        text-decoration: none;
        font-weight: normal;
        &:hover {
          color: $green;
        }
        @include mq(1920) {
          font-size: 17px;
        }
        @include mq(991) {
          font-size: 16px;
        }
      }
      & > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        & > li {
          padding: 9px 6px;
          font-size: 1.35rem;
          border-bottom: 1px solid #ededed;
          @include mq(1920) {
            font-size: 17px;
          }
          @include mq(991) {
            font-size: 17px;
          }
          & > a {
            color: $grey-text-dark;
            outline: none;
            text-decoration: none;
            font-family: "Gilroy";
          }
        }
      }
    }
  }
}

.domain-table {
  padding-top: 4.791666vw;
  @include mq(1920) {
    padding: 92px 10px 0;
  }
  @include mq(767) {
    padding: 50px 10px 0;
  }
}

.grey-table {
  margin: 2.5vw auto 5.8854vw auto;
  max-width: 87.5vw;
  width: 100%;
  border-collapse: collapse;
  background: $grey-table;
  @include mq(1920) {
    margin: 48px 0 113px;
    max-width: 1680px;
  }
  @include mq(1400) {
    width: 1380px;
    position: relative;
    will-change: left;
  }
}

.domain-table__box {
  display: flex;
  justify-content: center;
  & th {
    @extend .roboto-20-500;
    text-transform: uppercase;
    border-right: 2px solid $white;
    background: $green;
    color: $white;
    padding: 1.302vw 0;

    &:first-child {
      border-top-left-radius: 0.41666vw;
      border-bottom-left-radius: 0.41666vw;
      padding-left: 10px;

      @include mq(1920) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &:last-child {
      border-top-right-radius: 0.41666vw;
      border-bottom-right-radius: 0.41666vw;
      padding-right: 10px;
      border-right: none;

      @include mq(1920) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    @include mq(1920) {
      padding: 25px 0;
    }
  }

  & tr {
    &:last-of-type {
      & td {
        border-bottom: none;
      }
    }
  }

  & td {
    @extend .roboto-20-normal;
    line-height: 160%;
    background: $grey-table;
    padding: 0.83333vw 0;
    text-align: center;
    border-bottom: 1px solid #c9c9c9;

    @include mq(1920) {
      padding: 16px 0;
    }
  }

  @include mq(1400) {
    display: block;
  }
}

.servers-table__label-after {
  @extend .roboto-24-normal;
  color: #929292;
  text-align: center;
  margin-bottom: 64px;
  margin-top: -48px;

  @include mq(1921, min) {
    margin-bottom: 64px;
    margin-top: -48px;
  }
}
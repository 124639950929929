.payment-methods {
  background: $black-bg;
  padding: 9.375vw 50px;
  @include mq(1920) {
    padding: 180px 50px;
  }
  @include mq(1400) {
    padding: 100px 10px;
  }
  @include mq(767) {
    padding: 70px 10px;
  }
}

.payment-methods__inner {
  max-width: 72.91vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(1920) {
    max-width: 1400px;
  }

  @include mq(1400) {
    flex-direction: column;
  }
}

.payment-methods__left {
  width: 13.02vw;
  @include mq(1920) {
    width: 250px;
  }
  @include mq(1400) {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
}

.payment-methods__right {
  width: 50.52vw;
  @include mq(1920) {
    width: 970px;
  }
  @include mq(1050) {
    width: 800px;
    margin: 0 auto;
  }
  @include mq(860) {
    width: 635px;
  }
  @include mq(690) {
    width: 555px;
  }
  @include mq(590) {
    width: 410px;
  }
  @include mq(450) {
    width: 265px;
  }
}

.payment-methods__icons {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 2.86458vw;
  grid-column-gap: 2.86458vw;
  @include mq(1920) {
    grid-row-gap: 55px;
    grid-column-gap: 55px;
  }
  @include mq(1050) {
    grid-template-columns: repeat(5, 1fr);
  }
  @include mq(860) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include mq(690) {
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }
  @include mq(590) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq(450) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.payment-methods__item {
  align-self: center;
  background: $grey-bg;
  width: 6.04vw;
  height: 6.04vw;
  border-radius: 0.41666vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(1920) {
    width: 116px;
    height: 116px;
    border-radius: 8px;
  }
}

.payment-methods__icon {
  width: 2.2916vw;
  height: 2.2916vw;
  @include mq(1920) {
    width: 44px;
    height: 44px;
  }
}

.payment-methods__heading {
  @extend .gilroy-60-extrabold;
  color: $white;
  margin: 0 0 0.57vw;
  @include mq(1920) {
    margin: 0 0 11px;
  }
  @include mq(1920) {
    margin: 0 0 20px;
  }
}

.payment-methods__link {
  @extend .roboto-22-500;
  color: $white;
  text-decoration: none;
  display: inline-block;
  background: $green;
  padding: 0.833vw 2.96875vw;
  border-radius: 0.41666vw;
  will-change: background;
  transition: 0.25s;

  &:hover {
    background: $green-bg;
  }

  @include mq(1920) {
    padding: 16px 57px;
    border-radius: 8px;
  }
  @include mq(1920) {
    border-radius: 5px;
  }
  @include mq(767) {
    padding: 12px 40px;
    font-size: 14px;
  }
}

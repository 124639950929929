.build {
  text-align: center;
  padding: 4.843vw;
  @include mq(1920) {
    padding: 93px;
  }
  @include mq(1440) {
    padding: 70px 50px;
  }
  @include mq(767) {
    padding: 50px 10px;
  }
}

.build__btn {
  @extend .roboto-22-500;
  background: $green;
  border-radius: 0.26vw;
  color: $white;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
  margin-top: 1vw;
  padding: 0.8333vw 2.7vw;

  &:hover {
    background: $green-bg;
  }

  @include mq(1920) {
    border-radius: 5px;
    margin-top: 20px;
    padding: 16px 54px;
  }

  @include mq(450) {
    padding: 12px 25px;
  }
}

.text-form {
  padding: 10.9895vw 0;
  position: relative;
  z-index: 1;
  @include mq(1920) {
    padding: 211px 50px;
  }
  @include mq(1440) {
    padding: 100px 10px;
  }
  @include mq(767) {
    padding: 60px 10px;
  }
}

.text-form-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}

.text-form-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-form__top {
  background: $white;
  margin: 0 auto 2vw;
  max-width: 77.864vw;
  border-radius: 0.416vw;
  padding: 6.4583vw 0.52vw;
  position: relative;

  @include mq(1920) {
    border-radius: 8px;
    padding: 124px 10px;
    max-width: 1495px;
    margin: 0 auto 40px;
  }
  @include mq(1440) {
    padding: 90px 10px;
  }
  @include mq(900) {
    padding: 60px 10px;
  }
  @include mq(700) {
    padding: 50px 10px;
  }
  @include mq(550) {
    padding: 40px 10px;
  }
}

.text-form__discount {
  @extend .roboto-150-900;
  color: $discount;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0);
}

.domain-search {
  background: $green-light;
  max-width: 77.864vw;
  border-radius: 0.416vw;
  padding: 1.6666vw;
  position: relative;
  margin: 0 auto;

  & .select2-container--open {
    transform: translateY(0);
  }

  @include mq(1920) {
    border-radius: 8px;
    padding: 32px;
    max-width: 1495px;
  }
  @include mq(1250) {
    padding: 10px;
  }
  @include mq(767) {
    padding: 5px;
  }
}

.domain-search-inner {
  border-radius: 0.416vw;
  background: $white;
  @include mq(1920) {
    border-radius: 8px;
  }
}

.domain-search__form {
  display: flex;
}

.domain-search__input {
  @extend .roboto-24-normal;
  border: none;
  padding: 1.3541vw 2.968vw;
  flex: 1;

  &::placeholder {
    color: $grey-nav;
  }
  &:focus,
  &:active {
    outline: none;
  }
  @include mq(1920) {
    border-radius: 8px;
    padding: 26px 57px;
  }
  @include mq(1250) {
    padding: 20px 35px;
  }
  @include mq(767) {
    padding: 12px 20px;
    flex-shrink: 1;
  }
  @include mq(480) {
    padding: 8px 10px;
    flex-shrink: 1;
  }
  @include mq(430) {
    width: 200px;
  }
  @include mq(430) {
    width: 180px;
  }
}

.domain-search__btn {
  @extend .roboto-20-normal;
  border: none;
  background: $green-btn;
  border-radius: 0.416vw;
  color: $white;
  padding: 0 4.375vw;
  will-change: background;
  transition: 0.3s;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    background: $green-bg;
  }
  & svg {
    display: none;
    @include mq(420) {
      display: block;
      width: 17px;
      height: 17px;
    }
  }
  & span {
    @include mq(420) {
      display: none;
    }
  }
  @include mq(1920) {
    border-radius: 8px;
    padding: 0 84px;
  }
  @include mq(1250) {
    padding: 0 60px;
  }
  @include mq(767) {
    padding: 0 30px;
  }
  @include mq(420) {
    padding: 0 15px;
  }
}

.select2-container {
  width: 14.21vw !important;
  height: 2.6vw !important;
  border-left: 0.104vw solid $grey-border;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(1920) {
    width: 273px !important;
    height: 50px !important;
  }
  @include mq(1250) {
    width: 180px !important;
    height: 50px !important;
  }
  @include mq(1000) {
    width: 90px !important;
    height: 50px !important;
  }
  @include mq(480) {
    width: 70px !important;
    height: 30px !important;
  }
  @include mq(350) {
    width: 50px !important;
  }
}

.select2-container--default .select2-selection--single {
  border: none;
  &:focus,
  &:active {
    outline: none;
  }
}

.select2-selection__arrow {
  display: none;
}

.select2-dropdown {
  border: none;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: $green;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: $green;
  color: $white !important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  color: $white !important;
}

.select2-container--open {
  transform: translateY(3.645833vw);
  @include mq(1920) {
    transform: translateY(70px);
  }
  @include mq(1250) {
    transform: translateY(60px);
  }
  @include mq(1250) {
    transform: translateY(48px);
  }
  @include mq(480) {
    transform: translateY(61px);
  }
}

.select2-container--open .select2-dropdown {
  width: 7.812vw !important;
  left: 50%;
  transform: translate(-50%);

  @include mq(1920) {
    width: 150px !important;
  }
  @include mq(1000) {
    width: 90px !important;
  }
  @include mq(480) {
    width: 70px !important;
  }
  @include mq(350) {
    width: 51px !important;
  }
}

.select2-results__option {
  text-align: center;
}

.select2-selection__rendered {
  @extend .roboto-24-normal;
  color: $grey-nav !important;
  overflow: visible !important;
  padding: 0 20px !important;
  @include mq(480) {
    padding: 0 5px !important;
  }
}

.select2-results__option {
  @extend .roboto-24-normal;
  padding: 0.3125vw;
  color: $grey-nav !important;
  &:hover {
    color: $white !important;
  }
  @include mq(1920) {
    padding: 6px;
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 10.4166vw;
  @include mq(1920) {
    max-height: 200px;
  }
}

.login {
  background: #ededed;
  padding: 2vw 0px;
  @include mq(1920) {
    padding: 45px 0 75px;
  }
  @include mq(1399) {
    padding: 45px 0px 75px;
  }
  @include mq(767) {
    padding: 45px 0px 75px;
  }
  &__container {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;

    &__inner {
      padding: 0 15px;
      position: relative;
      width: 41.66666667%;
      margin: 0 auto;
      @include mq(767) {
        width: 100%;
      }
    }

    &__box {
      position: relative;
      padding: 0 15px;
    }
  }
}
.login-form-panel {
  background: #d9d8d8;
  border: 0;
  padding: 15px;
  border-radius: 5px;

  & > h3.badge {
    border-radius: 3px;
    padding: 11px 24px;
    display: table;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: -30px;
    font-size: 18px;
    background: $green;
    color: $white;
    font-family: "Gilroy";
  }
  .login-form {
    margin: 50px 0;
    text-align: center;
    background: $white;
    border: 0;
    border-radius: 8px;
    overflow: hidden;
    padding: 30px;
    p {
      font-family: "Roboto";
      font-size: 17px;
      font-weight: normal;
      line-height: 1.45;
      margin: 0 0 17px 0;
      font-weight: 300;
    }
    p a {
      font-size: 14px;
      color: $grey-text-dark;
      outline: none;
      text-decoration: none;
      &:hover {
        color: $green;
      }
    }
    input[type="submit"] {
      font-family: "Gilroy";
      letter-spacing: 0.5px;
      float: left;
      font-size: 16px;
      background: $green;
      height: 65px;
      color: $white;
      font-weight: 900;
      padding: 6px;
      text-transform: uppercase;
      width: 100%;
      border-radius: 4px;
      border: 0;
      cursor: pointer;
      -ms-box-sizing: border-box;
      -khtml-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  .login-form input[type="text"],
  .login-form input[type="password"] {
    font-size: 18px;
    padding: 15px;
    height: 65px;
    width: 100%;
    border: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #eeedee;
    border-radius: 5px;
    margin: 8px 0 18px;
    font-family: "Roboto";
  }
}
::placeholder {
  opacity: 0.3;
}
:-ms-input-placeholder {
  opacity: 0.3;
}
::-ms-input-placeholder {
  opacity: 0.3;
}

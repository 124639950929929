.culture {
  max-width: 63.125vw;
  margin: 0 auto;
  padding: 3.3333vw 0;
  text-align: center;
  @include mq(1920) {
    max-width: 1212px;
    padding: 64px 50px;
  }
  @include mq(767) {
    padding: 50px 10px 30px;
  }
}

.culture__image {
  text-align: center;
  margin: 0 auto 3.3333vw;
  width: 32.552vw;
  max-width: 100%;
  @include mq(1920) {
    margin: 0 auto 64px;
    width: 625px;
  }
  @include mq(900) {
    width: 425px;
  }
}

.questions {
  padding: 8.33vw 0.52vw 6.35416vw;
  @include mq(1440) {
    padding: 120px 10px 80px;
  }
  @include mq(767) {
    padding: 50px 10px;
  }
}

.questions__inner {
  max-width: 72.916vw;
  margin: 0 auto;
  @include mq(1920) {
    max-width: 1400px;
  }
}

.question {
  margin-bottom: 2.6vw;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0.4166vw;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(1920) {
    margin-bottom: 50px;
    border-radius: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(767) {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.question__name {
  @extend .gilroy-24-extrabold;
  transition: 0.3s;
  will-change: color;

  @include mq(767) {
    font-size: 16px;
  }
}

.question__top {
  display: flex;
  justify-content: space-between;
  padding: 2.86vw;
  align-items: center;
  cursor: pointer;

  &.open {
    & .question__name {
      color: $green;
    }
    & .question__svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    & .question__name {
      color: $green;
    }
  }

  @include mq(1920) {
    padding: 55px;
  }
  @include mq(900) {
    padding: 30px 20px;
  }
}

.question__svg {
  transition: 0.3s;
  will-change: transform;
  width: 1.25vw;
  height: 1.45833vw;
  margin-left: 15px;
  @include mq(1920) {
    width: 24px;
    height: 28px;
  }
  @include mq(1920) {
    width: 12px;
    height: 14px;
  }
}

.question__bottom {
  overflow: hidden;
  height: 0;
  transition: 0.3s;
  will-change: height;
}

.question__bottom-hidden {
  @extend .roboto-18-400;
  border-top: 1px solid $grey-border;
  padding: 2.3437vw 0;
  color: $grey-text;
  padding: 2.86vw 0;
  margin: 0 2.86vw;

  @include mq(1920) {
    padding: 55px 0;
    margin: 0 55px;
  }

  @include mq(1920) {
    padding-bottom: 45px 0;
  }
  @include mq(900) {
    padding: 30px 20px;
  }
}

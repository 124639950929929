// COLORS

$white: #fff;
$black: #272727;
$black-bg: #202020;
$green: #55a094;
$green-bg: #1f8c7b;
$green-light: #90d5ca;
$grey-text: #a6a6a6;
$grey-text-dark: #6e6e6e;
$green-btn: #4098a4;
$grey-bg: #313131;
$grey-nav: #929292;
$grey-light: #b2b2b2;
$grey-coin: #e5e5e5;
$discount: #f6f6f6;
$grey-table: #f9f9f9;
$grey-border: rgba(166, 166, 166, 0.3);

// EASINGS

$speed: 0.3s;
$bounce: cubic-bezier(0.17, 0.67, 0.3, 1.33);
$Power1EaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$Power2EaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$Power3EaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$Power4EaseOut: cubic-bezier(0.23, 1, 0.32, 1);
$Power1EaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$Power2EaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$Power3EaseIn: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$Power4EaseIn: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseIn: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);
$SineEaseOut: cubic-bezier(0.39, 0.575, 0.565, 1);
$SineEaseIn: cubic-bezier(0.47, 0, 0.745, 0.715);
$Power1EaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$Power3EaseInOut: cubic-bezier(0.77, 0, 0.175, 1);
$Power4EaseInOut: cubic-bezier(0.86, 0, 0.07, 1);
$SlowEaseOut: cubic-bezier(0.04, 1.15, 0.4, 0.99);
$easing: $Power2EaseOut;

.data-centers {
  max-width: 78.125vw;
  margin: 5.2vw auto 2.395vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq(1920) {
    max-width: 1600px;
    margin: 100px auto 46px;
    padding: 0 50px;
  }
  @include mq(1550) {
    max-width: 1100px;
  }
  @include mq(1050) {
    max-width: 500px;
    padding: 0;
    justify-content: center;
    padding: 0 10px;
  }
}

.data-center {
  width: 24.27vw;
  padding: 1.4vw 1.4vw 2.3958vw;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.04);
  border-radius: 2.08vw;
  margin-bottom: 2.8125vw;
  transition: 0.3s;

  // &:hover {
  //   box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.2);
  // }
  @include mq(1920) {
    width: 466px;
    padding: 27px 27px 46px;
    margin-bottom: 54px;
    border-radius: 40px;
  }
  @include mq(767) {
    padding: 15px 15px 30px;
    margin-bottom: 35px;
    border-radius: 20px;
  }
}

.data-centers__top {
  background: $green-light;
  border-radius: 50%;
  width: 4.167vw;
  height: 4.167vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.78vw;
  @include mq(1920) {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
}

.data-centers__name {
  @extend .gilroy-24-extrabold;
  margin: 0 0 0.9vw;
  @include mq(1920) {
    margin: 0 0 17px;
  }
}

.data-centers__text {
  @extend .roboto-18-normal;
  color: $grey-text;
  padding-top: 15px;
  border-top: 0.104vw solid $grey-border;

  @include mq(1920) {
    border-top: 2px solid $grey-border;
  }
}

.home-icons {
  padding: 8.5937vw 0;
  max-width: 71.354166vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(1920) {
    padding: 165px 0;
    max-width: 1370px;
  }
  @include mq(1550) {
    max-width: 1250px;
    padding: 145px 0;
  }
  @include mq(1350) {
    max-width: 1200px;
  }
  @include mq(1300) {
    max-width: 1150px;
  }
  @include mq(1250) {
    max-width: 1050px;
  }
  @include mq(1100) {
    flex-direction: column;
    padding: 80px 0;
  }
  @include mq(767) {
    flex-direction: column;
    padding: 50px 10px;
  }
}

.home-icons__left {
  width: 33.125vw;
  .heading {
    @extend .gilroy-60-extrabold;
    text-align: left;
  }
  .subheading {
    text-align: left;
  }
  @include mq(1920) {
    width: 636px;
  }
  @include mq(1250) {
    width: 490px;
    max-width: 100%;
  }
  @include mq(1250) {
    .heading {
      text-align: center;
    }
    .subheading {
      text-align: center;
    }
    margin-bottom: 50px;
  }
}

.home-icons__item-heading {
  @extend .roboto-24-500;
  margin: 0 0 0.416666vw;
  @include mq(1920) {
    margin: 0 0 8px;
  }
}

.home-icons__item-text {
  @extend .roboto-18-400;
  color: $grey-nav;
  max-width: 16.40625vw;
  @include mq(1920) {
    max-width: 315px;
  }
}

.home-icons__item {
  display: flex;
  align-items: center;
  margin-bottom: 2.083333vw;
  @include mq(1920) {
    margin-bottom: 40px;
  }
  @include mq(767) {
    margin-bottom: 30px;
  }
}

.home-icons__item-left {
  background: $green-light;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.416666vw;
  margin-right: 3.125vw;
  width: 3.541666vw;
  height: 3.541666vw;
  @include mq(1920) {
    max-width: 315px;
    border-radius: 8px;
    margin-right: 60px;
    width: 68px;
    height: 68px;
  }
  @include mq(767) {
    margin-right: 30px;
  }
  @include mq(450) {
    margin-right: 10px;
  }
  @include mq(410) {
    width: 48px;
    height: 48px;
    min-width: 48px;
    align-self: flex-start;
  }
}

.home-icons__right {
  width: 28.645833vw;
  padding-left: 5.2vw;
  position: relative;

  .home-icons__right-line {
    display: block;
    width: 1px;
    height: 80%;
    position: absolute;
    background: #c4c4c4;
    left: 0;
    top: 50%;
    transform: translateY(-50%) !important;
    @include mq(1100) {
      display: none;
    }
  }
  @include mq(1920) {
    width: 550px;
    padding-left: 100px;
  }
  @include mq(1350) {
    width: 500px;
    padding-left: 50px;
  }
  @include mq(1300) {
    width: 470px;
    padding-left: 30px;
  }
  @include mq(1250) {
    width: 500px;
    padding-left: 50px;
    max-width: 100%;
  }
  @include mq(767) {
    padding-left: 0;
  }
}

.home-icons__item-icon {
  width: 2.135416vw;
  @include mq(1920) {
    width: 41px;
  }
  @include mq(410) {
    width: 20px;
  }
}

.founded__top {
  display: flex;
  justify-content: space-between;
  max-width: 72.916vw;
  margin: 0 auto;
  padding: 6.25vw 0 4.9479vw;
  align-items: center;
  @include mq(1920) {
    max-width: 1400px;
    padding: 120px 0 95px;
  }
  @include mq(1600) {
    max-width: 1300px;
  }
  @include mq(1500) {
    max-width: 1200px;
  }
  @include mq(1440) {
    max-width: 1080px;
  }
  @include mq(1250) {
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq(767) {
    padding: 70px 0 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.founded__left {
  width: 35.20833vw;
  & .heading {
    text-align: left;
  }
  & .subheading {
    text-align: left;
  }
  @include mq(1440) {
    width: 510px;
  }
  @include mq(1250) {
    width: 100%;
    margin-bottom: 50px;
    & .heading {
      text-align: center;
    }
    & .subheading {
      text-align: center;
    }
  }
}

.founded__right {
  width: 33.17708vw;
  @include mq(1920) {
    width: 640px;
  }
  @include mq(1440) {
    width: 540px;
  }
  @include mq(767) {
    width: 440px;
    max-width: 100%;
  }
}

.founded__bottom {
  padding: 0 50px;
  & .heading {
    margin: 0 auto;
    max-width: 50.8vw;
    @include mq(1920) {
      max-width: 975px;
    }
    &__mb-2{
      margin-bottom: 2rem;
    }
  }
  & .subheading {
    margin: 0 auto;
    max-width: 57.92vw;
    @include mq(1920) {
      max-width: 1112px;
    }
  }
  @include mq(767) {
    padding: 0 10px;
  }
}

.founded__green {
  @extend .roboto-24-400;
  color: $green;
  text-align: center;
  margin: 1.25vw 0 3.125vw;
  @include mq(1920) {
    margin: 24px 0 60px;
  }
}

.founded__right-bg {
  width: 33.17708vw;
  @include mq(1920) {
    width: 640px;
  }
  @include mq(1440) {
    width: 540px;
  }
  @include mq(767) {
    width: 440px;
    max-width: 100%;
  }
}

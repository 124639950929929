.bg-image {
  position: relative;
  z-index: 1;
  color: $white;
  padding: 5.729vw 0;
  @include mq(1920) {
    padding: 110px 50px;
  }
  @include mq(1440) {
    padding: 90px 50px;
  }
  @include mq(767) {
    padding: 50px 10px;
  }
}

.bg-image--servers {
  position: relative;
  z-index: 1;
  color: $white;
  padding: 6.77vw 0;
  @include mq(1920) {
    padding: 130px 0;
  }
  @include mq(1440) {
    padding: 110px 0;
  }
  @include mq(767) {
    padding: 70px 0;
  }
  .bg-image__heading {
    @include mq(450) {
      font-size: 30px;
    }
  }
}

.bg-image__picture {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-image-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-image__text {
  margin: 0 auto;
}

.bg-image__text--one {
  margin: 0 auto;
  max-width: 52.604vw;
  @include mq(1920) {
    max-width: 1010px;
  }
}

.bg-image__text--two {
  margin: 0 auto;
  max-width: 63.5416vw;
  @include mq(1920) {
    max-width: 1220px;
  }
}

.bg-image__heading {
  @extend .gilroy-50-extrabold;
  text-align: center;
  margin: 0 0 0.416666vw;
  @include mq(1920) {
    margin: 0 0 8px;
  }
}

.bg-image__subheading {
  @extend .roboto-24-normal;
  text-align: center;
  margin: 0;
}

.bg-image__link {
  @extend .roboto-22-500;
  max-width: 100%;
  width: 26.04vw;
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-top: 0.8333vw;
  color: $green;
  background: $white;
  transition: 0.3s;
  padding: 0.8333vw;
  border-radius: 0.26vw;
  text-decoration: none;
  border: 2px solid $white;

  &:hover {
    color: $white;
    background: $green-bg;
  }
  @include mq(1920) {
    width: 500px;
    padding: 16px;
    margin-top: 16px;
    border-radius: 5px;
  }
  @include mq(1250) {
    width: 300px;
  }
  @include mq(450) {
    width: 240px;
    padding: 12px 16px;
  }
}

.bg-image__frame {
  position: absolute;
  left: 3.33333vw;
  top: 3.33333vw;
  height: calc(100% - 6.6666vvw);
  width: calc(100% - 6.6666vw);
  border: 0.15625vw solid $white;
  border-radius: 0.416666vw;

  @include mq(1920) {
    left: 64px;
    top: 64px;
    height: calc(100% - 128px);
    width: calc(100% - 128px);
    border: 3px solid $white;
    border-radius: 8px;
  }
  @include mq(1250) {
    left: 44px;
    top: 44px;
    height: calc(100% - 88px);
    width: calc(100% - 88px);
  }
  @include mq(767) {
    left: 15px;
    top: 25px;
    height: calc(100% - 50px);
    width: calc(100% - 30px);
  }
}

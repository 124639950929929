.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background: $white;
  width: 100%;
  padding: 2.1875vw 6.25vw;
  transition: 0.3s;
  will-change: padding;

  @include mq(1920) {
    padding: 42px 120px;
  }

  @include mq(1750) {
    padding: 42px 80px;
  }
  @include mq(1375) {
    padding: 25px 80px;
  }
  @include mq(767) {
    padding: 20px 40px;
  }
  @include mq(500) {
    padding: 20px 20px 20px 10px;
  }

  &.scroll {
    padding: 1.09vw 6.25vw;
    @include mq(1920) {
      padding: 21px 120px;
    }
    @include mq(1750) {
      padding: 21px 80px;
    }
    @include mq(1375) {
      padding: 15px 80px;
    }
    @include mq(767) {
      padding: 10px 40px;
    }
    @include mq(500) {
      padding: 10px 20px 10px 10px;
    }
  }
}

.navigation {
  margin-left: auto;
  display: flex;
  justify-content: space-between;

  @include mq(1375, min) {
    transform: translateX(0) !important;
  }

  @include mq(1375) {
    position: fixed;
    background: $white;
    top: 0;
    right: 0;
    z-index: 10;
    width: 320px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-items: center;
    transform: translateX(100%);
    will-change: transform;
  }

  @include mq(450) {
    width: 100%;
  }
}

.navigation__list {
  display: flex;
  align-items: center;
  margin-right: 2.6vw;
  @include mq(1920) {
    margin-right: 50px;
  }
  @include mq(1375) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.navigation__item {
  @extend .roboto-22-500;
  position: relative;
  margin: 0 2.6vw;

  &.active {
    & > .navigation__span {
      color: $green-light;
    }

    & > .navigation__link {
      color: $green-light;
    }
  }

  &:hover {
    & > .navigation__span {
      color: $green-light;
    }

    & > .navigation__link {
      color: $green-light;
    }
    & .navigation__sublist {
      visibility: visible;
      opacity: 1;

      & .navigation__item {
        animation: 0.25s fadeFromLeft forwards;
        opacity: 0;

        &:nth-child(1) {
          animation-delay: 0.1s;
        }
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.3s;
        }
        &:nth-child(4) {
          animation-delay: 0.4s;
        }
        &:nth-child(5) {
          animation-delay: 0.5s;
        }
      }
    }
  }

  @include mq(1920) {
    margin: 0 50px;
  }
  @include mq(1750) {
    margin: 0 25px;
  }
  @include mq(1375) {
    margin: 5px 0;
  }
}

.navigation__span {
  color: $grey-nav;
  transition: 0.3s;
  will-change: color;
  position: relative;
  cursor: default;
  z-index: 1;
  @include mq(1375) {
    display: none;
  }
}

.navigation__link {
  color: $grey-nav;
  text-decoration: none;
  transition: 0.3s;
  will-change: color;
}

.navigation__sublist {
  position: absolute;
  top: 100%;
  white-space: nowrap;
  background: $white;
  left: -1.04vw;
  padding: 0.52vw 1.04vw;
  border-radius: 0.3125vw;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  will-change: visibility, opacity;

  & .navigation__item {
    margin: 0.75vw 0;
    will-change: opacity, transform;

    @include mq(1920) {
      margin: 15px 0;
    }

    @include mq(1375, min) {
      animation: 0.25s fadeToLeft forwards;
    }

    @include mq(1375) {
      margin: 10px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include mq(1920) {
    left: -20px;
    padding: 10px 20px;
    border-radius: 6px;
  }

  @include mq(1375) {
    position: relative;
    opacity: 1;
    top: 0;
    left: 0;
    background: transparent;
    visibility: visible;
    padding: 0;
    text-align: center;
  }
}

.header__logo {
  width: auto;
  height: auto;

  @include mq(1920) {
    width: 100%;
    height: 70px;
  }
  @include mq(1375) {
    width: 350px;
    height: auto;
  }
  @include mq(767) {
    width: 250px;
    height: auto;
  }
  @include mq(500) {
    width: 200px;
    height: auto;
  }
}

.header__login {
  @extend .roboto-22-500;
  color: $white;
  text-decoration: none;
  background: $green-light;
  padding: 0.8333vw 1.875vw;
  border-radius: 0.4166vw;
  display: flex;
  align-items: center;
  transition: 0.25s;
  will-change: background;

  &:hover {
    background: $green;
  }

  @include mq(1920) {
    border-radius: 8px;
    padding: 16px 36px;
  }
}

.header__icon-img {
  margin-right: 1.14583vw;
  width: 0.833vw;
  height: 0.833vw;
  @include mq(1920) {
    margin-right: 22px;
    width: 16px;
    height: 16px;
  }
}

.hamburger {
  z-index: 30;
  display: none;
  @include mq(1375) {
    display: block;
  }
  width: 20px;
  height: 16px;
  position: relative;
  cursor: pointer;
}

.hamburger-one {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 1px;
  background: $grey-text;
}

.hamburger-two {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 7px;
  background: $grey-text;
}

.hamburger-three {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 1px;
  background: $grey-text;
}

.text-center {
  text-align: center;
}
.mx-auto{
  margin:0 auto;
}
.img-fluid{
  width:100%;
  height:auto;
}
.col-sm-2,.col-sm-11,.col-sm-10{
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-sm-11{
  @include mq(1920){
    width:91.66666667%;
  }
  @include mq(767){
    width:auto;
  }
}
.col-sm-10{
  @include mq(1920){
    width: 83.33333333%;
    float:left;
  }
  @include mq(767){
    width:auto;
  }
}
.col-sm-2{
  @include mq(1920){
    width: 16.66666667%;
    float:left;
  }
  @include mq(767){
    width:auto;
  }
}

